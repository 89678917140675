<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        treeTitle="组织架构"
        :isShowdig="false"
        :showCheckbox="false"
        :expandOnClickNode="false"
        :treeExpand="false"
        :currentNodeKey="currentNodeKey"
        :defaultExpandedKeys="defaultExpandedKeys"
        :nodeKey="'id'"
        :defaultCheckedKeys="defaultCheckedKeys"
        @getNodeClick="treeNodeClick"
      />
      <el-main style="position: relative;height: calc(100vh - 94px)">
        <head-layout
          class="head-section"
          :head-btn-options="headBtnOptions"
          head-title="政府部门安全生产处罚情况统计"
          @head-edit="headEdit"
          @head-save="headSave"
          @head-submit="headSubmit"
          @head-summary="headSummary"
          @head-reportView="headReportView"
        ></head-layout>
        <div class="head-picker">
          <el-date-picker
            v-model="query.statDate"
            type="month"
            class="searchInput"
            format="yyyy-MM"
            value-format="yyyy-MM"
            style="width: 200px"
            size="mini"
            @change="chooseDate"
            :disabled="type === 'workflow'"
          />
        </div>
        <div style="padding: 0 12px">
          <el-form :model="dataForm" :rules="rules" ref="dataForm" label-width="200px" class="demo-ruleForm">
            <el-row>
              <el-col :span="8">
                <el-form-item label="组织名称：" prop="companyName">
                  <el-input
                    v-model="dataForm.companyName"
                    controls-position="right"
                    :disabled="true"

                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="填报日期" prop="updateTime">
                  <template slot="label">
                    <span>填报日期:</span>
                  </template>
                  <el-date-picker
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="dataForm.updateTime"
                    type="date"
                    disabled="true"
                    placeholder="保存后自动生成"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="数据状态：" prop="dataState">
                  <el-select v-model="dataForm.dataState" disabled placeholder="请选择">
                    <el-option
                      v-for="item in dataStateDic"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="月检查次数：" prop="monthCheckCnt">
                  <el-input
                    v-model="dataForm.monthCheckCnt"
                    controls-position="right"
                    :disabled="state"
                    maxlength="8"
                    show-word-limit

                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.monthCheckCntS<0 || dataForm.monthCheckCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.monthCheckCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="月问题总数：" prop="monthProblemCnt">
                  <el-input
                    v-model="dataForm.monthProblemCnt"
                    controls-position="right"
                    :disabled="state"
                    maxlength="8"
                    show-word-limit

                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.monthProblemCntS<0 || dataForm.monthRectifiedCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.monthProblemCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="月整改数：" prop="monthRectifiedCnt">
                  <el-input
                    v-model="dataForm.monthRectifiedCnt"
                    controls-position="right"
                    :disabled="state"
                    maxlength="8"
                    show-word-limit

                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.monthRectifiedCntS<0 || dataForm.monthRectifiedCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.monthRectifiedCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="月处罚起数：" prop="monthAccumulatePunishCnt">
                  <el-input
                    v-model="dataForm.monthAccumulatePunishCnt"
                    controls-position="right"
                    :disabled="state"
                    maxlength="8"
                    show-word-limit

                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.monthAccumulatePunishCntS<0 || dataForm.monthAccumulatePunishCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.monthAccumulatePunishCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="月处罚罚款（万元）：" prop="monthAccumulatePunishAmount">
                  <el-input
                    v-model="dataForm.monthAccumulatePunishAmount"
                    controls-position="right"
                    :disabled="state"
                    maxlength="10"
                    show-word-limit

                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.monthAccumulatePunishAmountS<0 || dataForm.monthAccumulatePunishAmountS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.monthAccumulatePunishAmountS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="年累计检查数：" prop="yearAccumulateCheckCnt">
                  {{ yearAccumulateCheckCnt }}
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.yearAccumulateCheckCntS<0 || dataForm.yearAccumulateCheckCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.yearAccumulateCheckCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="年累计问题数：">
                  {{ yearAccumulateProblemCnt }}
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.yearAccumulateProblemCntS<0 || dataForm.yearAccumulateProblemCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.yearAccumulateProblemCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="年整改数：">

                  {{ yearRectifiedCnt }}
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.yearRectifiedCntS<0 || dataForm.yearRectifiedCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.yearRectifiedCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>

                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="月整改率：">

                  {{ monthRectifiedRate }}
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.monthRectifiedRateS == null || false?'上次填报数据：0':'上次填报数据：' + dataForm.monthRectifiedRateS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>

                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="年整改率：">

                  {{ yearRectifiedRate }}
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.yearRectifiedRateS == null || false?'上次填报数据：0':'上次填报数据：' + dataForm.yearRectifiedRateS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>

                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="年处罚起数：">

                  {{ yearAccumulatePunishCnt }}
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.yearAccumulatePunishCntS<0 || dataForm.yearAccumulatePunishAmountS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.yearAccumulatePunishCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>

                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="年处罚罚款（万元）：">

                  {{ yearAccumulatePunishAmount }}
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.yearAccumulatePunishAmountS<0 || dataForm.yearAccumulatePunishAmountS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.yearAccumulatePunishAmountS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>

                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <head-layout
          head-title="下级组织项目人员信息"
          :head-btn-options="subordinateBtn"
          @head-reject="reject"
          @head-urge-processing="headUrging"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          class="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :class="type != 'workflow'?'rptAdminPunishStat':''"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="rowView"
          @gird-handle-select-click="selectionChange"
          :cell-style="cellStyle"
        >
          <template #customBtn="{row}">
            <!--            <el-button-->
            <!--                type="text"-->
            <!--                v-if="row.dataState === '4' && (dataForm.dataState === '1' || dataForm.dataState === '3')"-->
            <!--                @click="rowEdit(row)"-->
            <!--            > 编辑-->
            <!--            </el-button>-->
            <!--            <el-button-->
            <!--              type="text"-->
            <!--              v-if="row.dataState === '4' && (dataForm.dataState === '1' || dataForm.dataState === '3')"-->
            <!--              @click="reject(row)"-->
            <!--            > 驳回-->
            <!--            </el-button>-->
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog
      v-dialog-drag
      :title="'政府部门安全生产处罚情况统计编辑'"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="environmentalFilling"
      v-if="environmentalFilling"
      width="80%"
      @close="environmentalDialog"
    >
      <environmentalSafetyProtectionSummary ref="environmentalSafetyProtectionSummary" :rowQuery="rowQuery"
                                            @environmentalDialog="environmentalDialog"></environmentalSafetyProtectionSummary>
    </el-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import CommonTree from "@/views/components/com_tree";
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {reportTreeAll, getDataStatus, reportTree} from "@/api/foreignReport/domesticReport";

import {
  summaryClickDataState,
  summaryClick,
  saveOrUpdate,
  summaryGetPage, detail, rejectSendingMessage
} from "@/api/report/protectionSafetyFilling";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {
  detail as getProcessDetail,
  detail as detailByProcess,
  processList as getProcessList
} from "@/api/plugin/workflow/process";
import Template from "@/views/message/template/list.vue";
import {dictionaryBiz} from "@/api/reportTasks";
import {formatTime} from "@/util";
import environmentalSafetyProtectionSummary
  from "@/views/business/reportManager/summaryReport/environmentalSafetyProtectionSummary/editDialog.vue";
import website from "@/config/website";
import {reviewAndUrging} from "@/api/reportReview";

export default {
  name: "dataSummary",
  components: {
    environmentalSafetyProtectionSummary,
    Template,
    FormLayout,
    HeadLayout,
    GridLayout,
    CommonTree,
  },
  mixins: [exForm, draft],
  data() {
    return {
      whether: true,
      defaultExpandedKeys: [],
      currentNodeKey: '',
      treeShow: false,
      nodeId: '',
      environmentalFilling: false,
      rowQuery: {},
      state: true,
      processTemplateKey: "report_summary_security_government",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      dataForm: {
        dateSource: "2"
      },
      rules: {
        companyName: [
          {required: true, message: '请输入组织名称', trigger: ['blur']},
        ],
        monthCheckCnt: [
          {required: true, message: '请输入月检查次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        monthProblemCnt: [
          {required: true, message: '请输入月问题总数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        monthRectifiedCnt: [
          {required: true, message: '请输入月整改数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        monthAccumulatePunishCnt: [
          {required: true, message: '请输入月处罚起数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        monthAccumulatePunishAmount: [
          {required: true, message: '请输入月处罚罚款（万元）', trigger: ['blur']},
          {pattern: /^\d+(\.\d{1,4})?$/, message: '请输入整数或小数，小数点后最多支持四位'}
        ]
      },
      dataState: '',
      dicData: [],
      dataStateDic: [],
      treeData: [],
      defaultProps: {
        label: "deptName",
        value: "deptName",
        key: "id",
      },
      query: {
        unitCode: "",
        templateCode: "rptAdminPunishStat",//境外编码
        statDate: '',
        statType: 'SAFE',
        id: "",
      },
      deptId: '',
      defaultCheckedKeys: [],
      searchTitle: "title",
      paperPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      saveLoading: false,
      tableLoading: false,
      type: undefined,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
    }
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  computed: {
    refresh() {
      return this.$store.state.common.report
    },
    ...mapGetters(["permission", 'colorName', 'userInfo']),
    //年累计检查数
    yearAccumulateCheckCnt() {
      let number = 0;
      if (this.dataForm.yearAccumulateCheckCntS || this.dataForm.monthCheckCnt) {
        number = ((this.dataForm.yearAccumulateCheckCntS != undefined ? Number(this.dataForm.yearAccumulateCheckCntS) : 0) - (this.dataForm.monthCheckCntS != undefined ? Number(this.dataForm.monthCheckCntS) : 0))
          + (this.dataForm.monthCheckCnt != undefined ? Number(this.dataForm.monthCheckCnt) : 0)
      }
      this.dataForm.yearAccumulateCheckCnt = number;
      return number;
    },
    //年累计问题数
    yearAccumulateProblemCnt() {
      let number = 0;
      if (this.dataForm.yearAccumulateProblemCntS || this.dataForm.monthProblemCnt) {
        number = ((this.dataForm.yearAccumulateProblemCntS != undefined ? Number(this.dataForm.yearAccumulateProblemCntS) : 0) - (this.dataForm.monthProblemCntS != undefined ? Number(this.dataForm.monthProblemCntS) : 0))
          + (this.dataForm.monthProblemCnt != undefined ? Number(this.dataForm.monthProblemCnt) : 0)
      }
      this.dataForm.yearAccumulateProblemCnt = number;
      return number;
    },
    //年整改数
    yearRectifiedCnt() {
      let number = 0;
      if (this.dataForm.yearRectifiedCntS || this.dataForm.monthRectifiedCnt) {
        number = ((this.dataForm.yearRectifiedCntS != undefined ? Number(this.dataForm.yearRectifiedCntS) : 0) - (this.dataForm.monthRectifiedCntS != undefined ? Number(this.dataForm.monthRectifiedCntS) : 0))
          + (this.dataForm.monthRectifiedCnt != undefined ? Number(this.dataForm.monthRectifiedCnt) : 0)
      }
      this.dataForm.yearRectifiedCnt = number;
      return number;
    },
    //年处罚起数
    yearAccumulatePunishCnt() {
      let number = 0;
      if (this.dataForm.yearAccumulatePunishCntS || this.dataForm.monthAccumulatePunishCnt) {
        number = ((this.dataForm.yearAccumulatePunishCntS != undefined ? Number(this.dataForm.yearAccumulatePunishCntS) : 0) - (this.dataForm.monthAccumulatePunishCntS != undefined ? Number(this.dataForm.monthAccumulatePunishCntS) : 0))
          + (this.dataForm.monthAccumulatePunishCnt != undefined ? Number(this.dataForm.monthAccumulatePunishCnt) : 0)
      }
      this.dataForm.yearAccumulatePunishCnt = number;
      return number;
    },
    //年处罚罚款（万元）
    yearAccumulatePunishAmount() {
      let number = 0;
      if (this.dataForm.yearAccumulatePunishAmountS || this.dataForm.monthAccumulatePunishAmount) {
        number = ((this.dataForm.yearAccumulatePunishAmountS != undefined ? parseFloat(Number(this.dataForm.yearAccumulatePunishAmountS).toFixed(4)) : 0) - (this.dataForm.monthAccumulatePunishAmountS != undefined ? parseFloat(Number(this.dataForm.monthAccumulatePunishAmountS).toFixed(4)) : 0))
          + (this.dataForm.monthAccumulatePunishAmount != undefined ? parseFloat(Number(this.dataForm.monthAccumulatePunishAmount).toFixed(4)) : 0)
      }
      this.dataForm.yearAccumulatePunishAmount = parseFloat(number.toFixed(4));
      return parseFloat(number.toFixed(4));
    },
    //月整改率
    monthRectifiedRate() {
      let monthRectified = "100%";
      if (this.dataForm.monthProblemCnt) {
        let monthRectifiedRate = (Number(this.dataForm.monthProblemCnt) === 0) ? "100%" : ((Number(this.dataForm.monthRectifiedCnt) / Number(this.dataForm.monthProblemCnt)) * 100).toFixed(0);
        monthRectified = monthRectifiedRate > 100 || monthRectifiedRate == "100%" ? "100%" : (monthRectifiedRate + "%");
      }
      this.dataForm.monthRectifiedRate = monthRectified;
      return monthRectified;
    },
    //年整改率
    yearRectifiedRate() {
      let yearRectified = "100%";
      if (this.yearAccumulateProblemCnt) {
        let yearRectifiedRate = (Number(this.yearAccumulateProblemCnt) === 0) ? "100%" : ((Number(this.yearRectifiedCnt) / Number(this.yearAccumulateProblemCnt)) * 100).toFixed(0);
        yearRectified = yearRectifiedRate > 100 || yearRectifiedRate == "100%" ? "100%" : (yearRectifiedRate + "%");
      }
      this.dataForm.yearRectifiedRate = yearRectified;
      return yearRectified;
    },
    headBtnOptions() {
      let result = [];
      if (this.whether) {
        if (this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == undefined || this.dataForm.dataState == "3") {
          result.push({
            label: '汇总',
            emit: "head-summary",
            type: "primary",
            icon: "el-icon-check",
            loading: this.saveLoading,
          })
        }
        if (this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == undefined || this.dataForm.dataState == "3") {
          if (!this.state) {
            result.push({
              label: '保存',
              emit: "head-save",
              type: "primary",
              icon: "el-icon-check",
              loading: this.saveLoading,
            })
          } else {
            result.push({
              label: '修改',
              emit: "head-edit",
              type: "primary",
              icon: "el-icon-check",
              loading: this.saveLoading,
            })
          }
        }
        if (this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == "3") {
          result.push({
            label: '提交',
            emit: "head-submit",
            type: "primary",
            icon: "el-icon-check",
            loading: this.saveLoading,
          })
        }
        // if (!((this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == undefined)) && this.dataForm.dataState != "3" && this.type != 'workflow') {
        //   result.push(
        //       {
        //         label: "流程详情",
        //         emit: "head-process_detail",
        //         type: "button",
        //         icon: "",
        //         btnOptType: "loseEfficacy",
        //       });
        // }
        // if (this.dataForm.dataState == "3" && this.type != 'workflow') {
        //   result.push(
        //     {
        //       label: "重新汇总",
        //       emit: "head-process_detail",
        //       type: "button",
        //       icon: "",
        //       btnOptType: "loseEfficacy",
        //     });
        // }
      }
      if (this.type != 'workflow') {
        result.push(
          {
            label: "报表查看",
            emit: "head-reportView",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });
      }

      return result
    },
    subordinateBtn() {
      let result = [];
      if (this.dataForm.dataState != "4") {
        result.push(
          {
            label: "退回重新填报",
            emit: "head-reject",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });

        result.push(
          {
            label: "催办",
            emit: "head-urge-processing",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });
      }
      return result;
    },
    searchColumns() {
      return [
        {
          label: "数据状态",
          prop: "dataState",
          type: "select",
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=data_status_report',
          dicData: [],
          placeholder: "请选择数据状态",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          span: 4,
        },
      ];
    },
    generateTopicsHeadOption() {
      return [
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
    tableOptions() {
      return {
        selection: true,
        selectable: (row, index) => {
          return row.id != -1;
        },
        menu: false,
        linklabel: "companyName",
        column: [
          {
            label: "机构名称",
            prop: 'companyName',
            align: "center",
            overHidden: true,
            fixed: 'left',
            width: 120,
          },
          {
            label: "填报状态",
            prop: "dataState",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
            fixed: 'left',
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 80,
          },
          {
            label: "统计月份",
            prop: 'statDate',
            align: "center",
            overHidden: true,
            fixed: 'left',
            width: 120,
          },
          {
            label: "业务类型",
            prop: "statType",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=statistical_categories",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "年累计检查数",
            prop: 'yearAccumulateCheckCnt',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "年整改数",
            prop: 'yearRectifiedCnt',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "月整改率",
            prop: 'monthRectifiedRate',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "年整改率",
            prop: 'yearRectifiedRate',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "年处罚起数",
            prop: 'yearAccumulatePunishCnt',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "年处罚罚款（万元）",
            prop: 'yearAccumulatePunishAmount',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "年累计问题数",
            prop: 'yearAccumulateProblemCnt',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "月检查次数",
            prop: 'monthCheckCnt',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "月问题总数",
            prop: 'monthProblemCnt',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "月整改数",
            prop: 'monthRectifiedCnt',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "月处罚起数",
            prop: 'monthAccumulatePunishCnt',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "月处罚罚款（万元）",
            prop: 'monthAccumulatePunishAmount',
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "数据来源",
            prop: "dateSource",
            align: "center",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=date_source",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 80,
          },
          {
            label: '创建时间',
            prop: 'createTime',
            align: "center",
            width: 170,
            overHidden: true
          },
          {
            label: "创建人",
            prop: "createUserName",
            align: "center",
            width: 70,
            overHidden: true,
          },
          {
            label: "更新日期",
            prop: "updateTime",
            align: "center",
            width: 170,
            overHidden: true,
          },
          {
            label: "更新人",
            prop: "updateUserName",
            align: "center",
            width: 70,
            overHidden: true,
          },
        ],
      }
    },
  },
  methods: {
    restRefresh() {
      this.$store.commit('SET_REPORT', !this.refresh)
    },
    cellStyle({row, column, rowIndex, columnIndex}) {
      const value = row.dataState;
      if (value && column.property) {
        if (value == '1' && column.property == 'dataState') {
          return {color: '#e6a23c'};
        } else if (value == '2' && column.property == 'dataState') {
          return {color: '#409eff'};
        } else if (value == '3' && column.property == 'dataState') {
          return {color: '#f56c6c'};
        } else if (value == '4' && column.property == 'dataState') {
          return {color: '#67c23a'};
        } else {
          return {}; // 默认情况下不设置特定的背景色
        }
      }
    },
    // 查看流程详情
    headProcessDetail() {
      const processInsId = this.dataForm.processInsId;
      detailByProcess({processInsId: processInsId}).then((res) => {
        const {process} = res.data.data;
        const {id, taskId, processInstanceId, processId, processDefKey} =
          process;
        let param = Buffer.from(
          JSON.stringify({
            processId: id,
            taskId,
            processInsId: processInstanceId || processId,
            processDefKey,
          })
        ).toString("base64");
        //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
        let sUrl =
          window.location.origin + "#/process/external/iframe/detail?p=" + param + '&isNewWindow=true';
        window.open(sUrl, "_blank");
      });
    },
    receiveData(row) {
      let templateCode = "rptAdminPunishStat";
      let type = "summary";
      this.$loading();
      let formDate = formatTime(row.statDate, "yyyy-MM");
      reportTree(templateCode, type, row.fillDeptId, formDate)
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.treeData[0].id)
          })
          this.defaultExpandedKeys.push(row.fillDeptId)
          this.currentNodeKey = row.fillDeptId;
          this.nodeId = row.fillDeptId;
          this.dataForm.companyName = row.fillDeptName;
          this.companyName = row.fillDeptName;
          this.query.unitCode = this.nodeId;
          this.query.statDate = row.statDate;
          this.dataForm.statDate = row.statDate
          this.summaryGetDataList()
          this.onLoad(this.page);
          this.treeShow = true;
        })
        .catch((err) => {
          this.$loading().close();
          this.treeLoading = false;
        }).finally(() => {
        this.$loading().close();
      });
    },
    initData() {
      if (this.treeData.length != 0) {
        this.defaultCheckedKeys.push(this.treeData[0].id)
      }
      this.treeShow = true
      // this.onLoad(this.page);
    },
    findDictValueByState() {
      if (this.dataForm && this.dataForm.dataState && Array.isArray(this.dicData)) {
        const item = this.dicData.find(item => item.dictKey === this.dataForm.dataState);
        return item ? item.dictValue : null;
      } else {
        return null;
      }
    },
    chooseDate(e) {
      this.state = true;
      if (this.query.statDate) {
        this.summaryGetDataList();
      } else {
        this.dataForm = [];
        this.dataForm.companyName = this.companyName
      }
      this.onLoad(this.page)
    },
    summaryGetDataList() {
      let data = {
        statDate: this.query.statDate,
        unitCode: this.query.unitCode,
        statType: "SAFE"
      }
      detail(data).then((res) => {
        if (res.data.code === 200) {
          this.dataForm = res.data.data
          this.dataState = this.findDictValueByState()
        }
        if (!res.data.data.dataState) {
          this.dataForm.dataState = "1"
        }
        if (!this.dataForm.companyName) {
          this.dataForm.companyName = this.companyName
        }
      })
    },
    //提交
    headSubmit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.dataForm.id && this.dataForm.id != -1) {
            var row = this.dataForm;
            if (row.dataState == 2 || row.dataState == 4) {
              return this.$message({
                type: "warning",
                message: "已提交或已通过的数据不可重复提交"
              });
            }
            this.dataForm.templateCode = "rptAdminPunishStat"
            summaryClickDataState(this.dataForm).then(res => {
              if (res.data.code === 200) {
                if (res.data.data) {
                  this.$confirm("下级还有数据未通过，是否继续提交?", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                  }).then(() => {
                    this.$confirm("确定将提交?", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning"
                    })
                      .then(() => {
                        this.$loading();
                        this.rpTaskAttributeSubmit(row);
                      }).catch(() => {
                    })
                      .finally(() => {
                        this.$loading().close()
                      })
                  });
                } else {
                  this.$confirm("确定将提交?", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                  })
                    .then(() => {
                      this.$loading();
                      this.rpTaskAttributeSubmit(row);
                    }).catch(() => {
                  })
                    .finally(() => {
                      this.$loading().close()
                    })
                }
              }
            })
          } else {
            this.$message.warning("请先保存数据");
          }
        } else {
          this.$message.warning("当前报表数据有误，请先汇总数据或修改数据！");
        }
      })
    },
    headSummary() {
      if (this.dataForm.id == undefined || this.dataForm.id == -1) {
        if (this.query.statDate) {
          this.$loading()
          summaryClick(this.query).then(res => {
            if (res.data.code === 200 && res.data.data != "有任务未完成不能汇总,详情请通过搜索条件去确认未完成任务." && res.data.data != "noSubordinate") {
              this.dataForm = res.data.data;
              this.dataForm.companyName = this.companyName;
              this.headSave();
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              if (res.data.data == "noSubordinate") {
                this.$confirm("没有下级数据，无需汇总!", {
                  showCancelButton: false,
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: "warning"
                })
              } else {
                this.$confirm(res.data.data, {
                  showCancelButton: false,
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: "warning"
                })
              }
            }
          }).then(() => {
            return;
          }).finally(() => {
            this.$loading().close();
          })
        } else {
          this.$message.warning("请选择统计时间");
        }
      } else {
        detail(this.dataForm).then((res) => {
          if (res.data.code === 200) {
            this.dataForm = res.data.data
            this.query.unitCode = res.data.data.unitCode
            this.query.id = res.data.data.id
            this.dataForm.companyName = res.data.data.companyName
            this.dataState = this.findDictValueByState()
            this.$nextTick(() => {
              if (this.dataForm.dataState == 2 || this.dataForm.dataState == 4) {
                this.state = true;
              }
            })
            if (this.query.statDate) {
              summaryClick(this.query).then(res => {
                if (res.data.code === 200 && res.data.data != "有任务未完成不能汇总,详情请通过搜索条件去确认未完成任务." && res.data.data != "noSubordinate") {
                  this.dataForm = res.data.data;
                  this.dataForm.companyName = res.data.data.companyName
                  this.query.id = "";
                  this.headSave();
                  this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
                } else {
                  if (res.data.data == "noSubordinate") {
                    this.$confirm("没有下级数据，无需汇总!", {
                      showCancelButton: false,
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: "warning"
                    })
                  } else {
                    this.$confirm(res.data.data, {
                      showCancelButton: false,
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: "warning"
                    })
                  }
                }
              }).then(() => {

              }).finally(() => {
              })
            } else {
              this.$message.warning("请选择统计时间");
            }
          }
        })
      }
    },
    headEdit() {
      this.state = false;
    },
    getByIdData() {
      detail(this.dataForm).then((res) => {
        if (res.data.code === 200) {
          this.dataForm = res.data.data
          this.query.unitCode = res.data.data.unitCode
          this.dataForm.companyName = res.data.data.companyName
          this.query.statDate = res.data.data.statDate
          this.dataState = this.findDictValueByState()
          this.$nextTick(() => {
            if (this.dataForm.dataState == 2 || this.dataForm.dataState == 4) {
              this.state = true;
            }
          })
          if (this.type === 'workflow') {
            this.onLoad(this.page, {});
          }
        }
      })
    },
    headSave() {
      if (this.type !== 'workflow') {
        if (this.nodeId) {
          if (this.query.statDate) {
            if (this.dataForm.dataState == undefined || this.dataForm.dataState == "") {
              this.dataForm.dataState = 1;
            }
            this.dataForm.unitCode = this.nodeId
            //设置组织id和名称
            this.dataForm.statDate = this.query.statDate
            this.dataForm.companyName = this.companyName
            this.dataForm.dateSource = "2"
            this.dataForm.statType = "SAFE"
            this.$nextTick(() => {
              this.$refs.dataForm.validate((valid) => {
                if (valid) {
                  this.$loading();
                  saveOrUpdate(this.dataForm).then(res => {
                    if (res.data.code === 200 && res.data.data != "数据已存在不能重复汇总!") {
                      this.dataForm = res.data.data;
                      if (this.dataForm.dataState == 2 || this.dataForm.dataState == 3) {
                        this.state = true;
                      }
                      this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
                    } else {
                      this.$message.warning(res.data.data);
                    }
                    this.getByIdData();
                  })
                    .finally(() => {
                      this.$loading().close();
                    })
                }
              })
            })
          } else {
            this.$message.warning("请选择统计时间");
          }
        } else {
          this.$message.warning("部门编码为空");
        }
      } else {
        // 保存逻辑
        this.$nextTick(() => {
          this.$refs.dataForm.validate((valid) => {
            if (valid) {
              this.$loading();
              this.dataForm.statType = "SAFE"
              saveOrUpdate(this.dataForm, this.type).then(res => {
                if (res.data.code === 200 && res.data.data != "数据已存在不能重复汇总!") {
                  this.dataForm = res.data.data;
                  if (this.dataForm.dataState == 2 || this.dataForm.dataState == 3) {
                    this.state = true;
                  }
                  this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
                } else {
                  this.$message.warning(res.data.data);
                }
                this.getByIdData();
              })
                .finally(() => {
                  this.$loading().close();
                })
            }
          })
        })
      }
    },
    headReportView() {
      if (this.nodeId) {
        if (this.query.statDate) {
          // 将字符串转换为 Date 对象
          // const dateObject = new Date(this.query.statDate);
          // // 使用 toISOString 获取 ISO 格式的日期字符串
          // // 然后我们截取前10个字符，即 'YYYY-MM' 部分
          // const formattedDate = dateObject.toISOString().substring(0, 7);
          window.open('/#/business/reportManager/reportView/index?isNewWindow=true&deptId=' + this.nodeId + "&reportCode=rptAdminPunishStat&stat_month=" + this.query.statDate)
        } else {
          window.open('/#/business/reportManager/reportView/index?isNewWindow=true&deptId=' + this.nodeId + "&reportCode=rptAdminPunishStat")
        }
      } else {
        this.$message.warning("请先选择组织")
      }
    },
    initTree() {
      let templateCode = "rptAdminPunishStat";
      reportTreeAll(templateCode)
        .then((result) => {
          this.treeData = result.data.data;
          if (this.treeData == "您不需要填报该报表!") {
            this.$message.warning("您不需要填报该报表!")
            return
          }
          this.node = this.treeData[0];
          if (this.type != 'workflow') {
            this.query.unitCode = this.nodeId;
          }
          if (this.currentNodeKey == '') {
            this.$nextTick(() => {
              this.defaultCheckedKeys.push(this.treeData[0].id)
            })
          }
          this.treeLoading = false;
        })
        .catch((err) => {
          this.treeLoading = true;
        });
      if (!this.dataForm.dataState) {
        this.dataForm.dataState = "1"
      }
    },
    rpTaskAttributeSubmit(item) {
      this.dataForm.totalCount = this.totalChineseCnt
      if (!this.dataForm.id) {
        this.dataForm.createUserName = this.userInfo.real_name
      }
      this.dataForm.updateUserName = this.userInfo.real_name
      this.dataForm.unitCode = this.nodeId
      this.dataForm.dataState = 4;
      this.dataForm.statType = "SAFE"
      this.dataForm.unitCode = this.nodeId
      saveOrUpdate(this.dataForm).then((res) => {
        if (res.data.code === 200) {
          this.restRefresh();
          this.getByIdData();
          this.$message({
            message: "操作成功",
            type: "success",
          });
        }
      }).finally(() => {
        this.$loading().close()
      })
      // this.formProcess.id = item.id;
      // this.formProcess.deptId = this.nodeId;
      // this.formProcess.paperName = this.dataForm.paperName;
      // this.handleStartProcess5(true,true).then((res) => {
      //   let processInsId = res.data.data;
      //   item.processInsId = processInsId;
      //   item.dataState = 2;
      //   item.statType = "SAFE"
      //   item.unitCode = this.nodeId
      //   getProcessDetail({processInsId: processInsId}).then((res) => {
      //     if (res.data.data.flow[0].assigneeIds) {
      //       item.assigneeIds = res.data.data.flow[0].assigneeIds
      //     }
      //     saveOrUpdate(item).then((res) => {
      //       if (res.data.code === 200) {
      //         this.restRefresh();
      //         this.getByIdData();
      //         this.$message({
      //           message: "操作成功",
      //           type: "success",
      //         });
      //       }
      //     })
      //   });
      // });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
    selectionChange(list) {
      this.selectionList = list
    },
    gridHeadEmpty(query) {
      this.onLoad(this.paperPage, query)
    },
    gridHeadSearch(query) {
      this.paperPage.currentPage = 1;
      this.onLoad(this.paperPage, query)
    },
    treeNodeClick(node) {
      this.whether = node.whether
      this.dataForm = [];
      this.state = true
      this.node = node;
      this.nodeId = node.id;
      this.classifyId = this.nodeId;
      this.query.unitCode = this.nodeId;
      this.companyName = this.node.deptName
      if (this.query.statDate) {
        this.summaryGetDataList();
      }
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    closeDialog() {
      this.selectionList = [];
      this.paperPage.currentPage = 1;
      this.selectTopicsDialog = false;
    },
    selectionClear() {
      this.selectionList = [];
    },
    rowView(row) {
      this.environmentalFilling = true
      this.rowQuery = {
        type: "view",
        row: JSON.stringify(row),
      }
    },
    rowEdit(row) {
      this.environmentalFilling = true
      this.rowQuery = {
        type: "edit",
        row: JSON.stringify(row),
      }
    },
    headUrging() {
      if (this.selectionList.length == 0) {
        this.$message.warning("请勾选数据！")
        return;
      }
      const hasDataStateNotEqualToTwo = this.selectionList.some(item => item.dataState != "1" && item.dataState != "3");
      if (hasDataStateNotEqualToTwo) {
        this.$message.warning("请确认勾选的数据状态为待提交或驳回！")
      } else {
        this.$loading()
        const result = this.selectionList.map(item => item.unitCode).join(',');
        let data = {deptId: result, reportCode: "rptAdminPunishStat"}
        reviewAndUrging(data)
          .then((result) => {
            if (result.data.code == 200) {
              this.$loading().close()
              this.onLoad(this.page);
              this.$message.success("催办成功!")
            }
          }).finally(() => {
          this.$loading().close()
        })
      }
    },
    reject() {
      if (this.selectionList.length !== 0) {
        let idList = [];
        this.selectionList.forEach((e) => {
          if (e.dataState != "4") {
            idList.push(e.id)
          }
        });
        if (idList.length === 0) {
          this.$prompt('请输入驳回原因:', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputType: "textarea",
            inputPattern: /^[\s\S]{1,200}$/, // 使用正则表达式限制长度在1到200个字符之间
            inputErrorMessage: '请输入1到200个字符的驳回原因', // 当输入不符合正则表达式时显示的错误信息
            closeOnClickModal: false
          }).then(({value}) => {
            let templateCode = "rptAdminPunishStat"
            this.$loading()
            rejectSendingMessage(this.selectionList, value, templateCode).then((res) => {
              if (res.data.code == 200) {
                this.onLoad(this.page);
                this.$message({
                  type: 'success',
                  message: '驳回成功'
                });
              }
            }).catch((e) => {
              this.$message({
                type: 'warning',
                message: '取消驳回'
              });
            }).finally(() => {
              this.$loading().close()
            })
          }).catch(() => {
            this.$message({
              type: 'warning',
              message: '取消驳回'
            })
          }).finally(() => {
            this.$loading().close()
          });
        } else {
          return this.$message.warning("请确保选择数据中全部是已通过!")
        }
      } else {
        return this.$message.warning("至少选择一条数据!")
      }
    },
    environmentalDialog() {
      this.environmentalFilling = false;
      this.onLoad(this.page)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.crudLoading = true;
      summaryGetPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records;
        this.crudLoading = false;
        this.selectionClear();
      }).catch(() => {
        this.tableData = []
      }).finally(() => {
        this.crudLoading = false;
      });
    },
  },
  mounted() {
    // this.getProcessId()
    if (this.type === 'workflow') {
      this.dataForm.id = this.$route.query.id
      this.dataForm.statType = 'SAFE'
      this.getByIdData();
    }
    if (this.type != 'workflow') {
      // this.initTree()
    } else {
      this.treeShow = false
    }
  },
  created() {
    dictionaryBiz("data_status_report").then((res) => {
      this.dataStateDic = res.data.data;
    });
    getDataStatus().then((res) => {
      this.dicData = res.data.data;
    })
    let type = this.$route.query.type;
    let statDate = formatTime(new Date(), "yyyy-MM");
    this.query.statDate = statDate;
    this.type = type
  }
}
</script>

<style lang="scss" scoped>
.custom-grid-layout {
  height: 100%;
}

.head-section {
  margin-bottom: 20px;
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

::v-deep .el-form-item__content {
  display: flex;
}

.searchInput {
  width: 300px;
  position: absolute;
  top: 9px;
  right: 255px;
}

.head-picker ::v-deep .el-date-editor.el-input {
  width: 20% !important;
}

//::v-deep .rptAdminPunishStat .avue-crud .el-table {
//  height: calc(100vh - 569px) !important;
//  max-height: calc(100vh - 569px) !important;
//}

::v-deep .gridHeadLayout .el-button-group > .el-button:last-child {
  display: none !important;
}

.demo-ruleForm ::v-deep el-date-picker {
  width: 100% !important;
}
</style>
